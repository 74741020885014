@import '../node_modules/@angular/material/theming';
@import 'enerbank-color-palettes';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$eb-app-primary: mat-palette($eb-mat-blue);
$eb-app-accent:  mat-palette($eb-mat-dark-grey, 500, 400, 700);

// The warn palette is optional (defaults to red).
$eb-app-warn:    mat-palette($eb-mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$eb-app-theme: mat-light-theme($eb-app-primary, $eb-app-accent, $eb-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($eb-app-theme);

/***
 * Custom global styling
 */

// Recommended Angular Material icon sizing.
.material-icons.md-18 { font-size: 18px; height: 18px; width: 18px;}
.material-icons.md-24 { font-size: 24px; height: 24px; width: 24px; }
.material-icons.md-36 { font-size: 36px; height: 36px; width: 36px; }
.material-icons.md-48 { font-size: 48px; height: 48px; width: 48px; }

/* Angular Table sorting arrow custom styling */
.mat-sort-header-pointer-left,
.mat-sort-header-pointer-right,
.mat-sort-header-pointer-middle,
.mat-sort-header-stem {
  background: $eb-blue !important;
}

/* Material Tabs Custom Styling */
mat-tab-group {
  mat-tab-header {
    background-color: $eb-lighter-grey-2;
    .mat-tab-label-active {
      color: $eb-blue;
      opacity: 1;
    }
    .number-circle {
      background: $eb-lighter-grey-3;
      color: $eb-white;
    }
  }
  div[aria-selected="true"] {
    .number-circle {
      background: $eb-blue;
    }
  }
}

/* Material Checkbox Custom Styling */
mat-checkbox {
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $eb-blue;
  }
}