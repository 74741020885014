// Generated from base color palette with a
// Material palette generator from base Enerbank colors.
$eb-mat-black: (
    50: #e7e7e7,
    100: #c2c2c2,
    200: #999999,
    300: #707070,
    400: #525252,
    500: #333333,
    600: #2e2e2e,
    700: #272727,
    800: #202020,
    900: #141414,
    A100: #ef6e6e,
    A200: #ea4040,
    A400: #f60000,
    A700: #dd0000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$eb-mat-grey: (
    50: #f8f8f8,
    100: #ececec,
    200: #e0e0e0,
    300: #d4d4d4,
    400: #cacaca,
    500: #c1c1c1,
    600: #bbbbbb,
    700: #b3b3b3,
    800: #ababab,
    900: #9e9e9e,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffdfdf,
    A700: #ffc5c5,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$eb-mat-dark-grey: (
    50: #eeeeee,
    100: #d4d4d4,
    200: #b8b8b8,
    300: #9b9b9b,
    400: #858585,
    500: #707070,
    600: #686868,
    700: #5d5d5d,
    800: #535353,
    900: #414141,
    A100: #f5a9a9,
    A200: #f07b7b,
    A400: #ff3a3a,
    A700: #ff2020,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$eb-mat-blue: (
    50: #e0eff8,
    100: #b3d7ed,
    200: #80bce1,
    300: #4da1d4,
    400: #268dcb,
    500: #0079c2,
    600: #0071bc,
    700: #0066b4,
    800: #005cac,
    900: #00499f,
    A100: #c9deff,
    A200: #96bfff,
    A400: #63a0ff,
    A700: #4a90ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$eb-mat-green: (
    50: #e0f5ee,
    100: #b3e5d5,
    200: #80d4ba,
    300: #4dc39e,
    400: #26b689,
    500: #00a974,
    600: #00a26c,
    700: #009861,
    800: #008f57,
    900: #007e44,
    A100: #acffd2,
    A200: #79ffb7,
    A400: #46ff9c,
    A700: #2cff8e,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$eb-mat-red: (
    50: #ffebe8,
    100: #ffcec6,
    200: #ffada0,
    300: #fe8c79,
    400: #fe745d,
    500: #fe5b40,
    600: #fe533a,
    700: #fe4932,
    800: #fe402a,
    900: #fd2f1c,
    A100: #ffffff,
    A200: #fffefe,
    A400: #ffcfcb,
    A700: #ffb7b2,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Base Enerbank color palette.
$eb-black: #333333;
$eb-white: #ffffff;
$eb-lighter-grey: #fafafa;
$eb-lighter-grey-2: #f5f5f5;
$eb-lighter-grey-3: #d3d3d3;
$eb-lighter-grey-shadow: #e6e6e6;
$eb-light-grey: #c1c1c1;
$eb-dark-grey: #707070;
$eb-blue: #0079c2;
$eb-blue-2: #0078d7;
$eb-blue-3: #29a9e0;
$eb-green: #00a974;
$eb-green-2: #56b146;
$eb-green-3: #84D868;
$eb-red: #fe5b40;
$eb-yellow: #f8a729;
$eb-yellow-2: #f99d3a;
