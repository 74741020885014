/* You can add global styles to this file, and also import other style files */
@import './utilities/mixins';
@import './utilities/variables';

/* Global Styles */
html,
body {
    margin: 0;
    height: 100%;
}
* {
    font-family: Roboto, Helvetica Neue, sans-serif ; 
    /* Add !important to overwrite all elements */
  }
mat-sidenav-container {
    height: calc(100% - #{$eb-app-header-height});
}

/*
https://stackoverflow.com/questions/48688614/angular-custom-style-to-mat-dialog

The Angular Material dialog container (just the dialog window itself) can only be styled at the global scope.
When a parent component opens a dialog, a CSS class name can be provided as a parameter that will be assigned
to the dialog container.

This class represents the dialog popup for setting the Call dates and times of an Authorization.
*/
.dialog-container > .mat-dialog-container {
    padding: 0;
}

/* Styles for Mat-Tabs */
mat-tab-group {
    mat-tab-header {
        // TODO: Create eb-number-circle component to hold these styles.  Then implement it wherever this is consumed.
        .number-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            padding: 3px;
            margin-right: 10px;
            text-align: center;
            font: 12px Roboto, sans-serif;
        }
    }
}

/* Styles for Mat-Card */
eb-root .mat-card:not([class*='mat-elevation-z']) {
    padding: 20px;
    @include eb-box-shadow;
}

table[mat-table],
mat-table {
    @include eb-box-shadow;
}

.ach-option-select {
    margin-top: 28px;
    margin-left: 10px;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }
  
// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

mat-header-cell{
    color: #0000008a;
}

.mt-tooltip { 
    font-size: 10px !important;
    height: 30px !important;
}

.content-filter-menu .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
    align-self: center !important;
    margin-top: 0 !important;
    margin-right: 0px !important;
  }
